// 文章量に応じてtextareaの高さを変更する
// 参：https://web-dev.tech/front-end/javascript/textarea-auto-height/
export default class {
  constructor(el) {
    this.dom = {el: el};

    this.borderTopWidth = 0;
    this.borderBottomWidth = 0;

    this.setHeight = this.setHeight.bind(this);
    this.resize = this.resize.bind(this);

    this.init();
  }

  init() {
    // ボーダーを加味しておく
    const style = getComputedStyle(this.dom.el);
    this.borderTopWidth = parseInt(style.borderTopWidth);
    this.borderBottomWidth = parseInt(style.borderBottomWidth);

    // 高さをセットしておく(最初からテキストが入っている時用)
    this.setHeight();

    // inputイベントごとに高さをセット
    this.dom.el.addEventListener('input', this.setHeight);

    // resize
    window.addEventListener('resize', this.resize);
  }

  resize() {
    const style = getComputedStyle(this.dom.el);
    this.borderTopWidth = parseInt(style.borderTopWidth);
    this.borderBottomWidth = parseInt(style.borderBottomWidth);

    this.borderWidth = parseInt(getComputedStyle(this.dom.el).borderWidth);
    this.setHeight();
  }

  setHeight() {
    this.dom.el.style.height = "auto";
    this.dom.el.style.height = `${this.dom.el.scrollHeight + this.borderTopWidth + this.borderBottomWidth}px`;
  }
}
