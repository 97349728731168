import { CFG } from '../config';
import { isIE } from '../utility';

export default class {
  constructor(el) {
    this.dom = {el: el};
    this.dom.trigger = this.dom.el;
    this.dom.target = document.querySelector('[data-js="account-menu"]');

    this.isOpen = false;

    this.toggleMenu = this.toggleMenu.bind(this);

    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.init();
  }

  init() {

    // ブレイクポイントの瞬間に発火
    this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    this.checkBreakPoint(this.mql);
  }

  toggleMenu() {
    if (this.isOpen) {
      this.dom.trigger.setAttribute('aria-expanded', false);
      this.dom.target.setAttribute('aria-hidden', true);
    } else {
      this.dom.trigger.setAttribute('aria-expanded', true);
      this.dom.target.setAttribute('aria-hidden', false);
    }
    this.isOpen = !this.isOpen;
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      this.dom.trigger.addEventListener('click', this.toggleMenu);
      return;
    } else {
      // console.log('pc');
      this.dom.trigger.removeEventListener('click', this.toggleMenu);
      return;
		}
	}
}
