import smoothscroll from 'smoothscroll-polyfill';
// import IntersectionObserver from './_main/IntersectionObserver';

import { isIE } from './utility';
import { CFG } from './config';
import Account from './_main/Account';
import Hamburger from './_main/Hamburger';
// import HamburgerMenu from './_main/HamburgerMenu';
// import DropdownMenu from './_main/DropdownMenu';
import AnchorScroll from './_main/AnchorScroll';
import Accordion from './_main/Accordion';
// import LoadMore from './_main/LoadMore';
// import ScrollManager from './_main/ScrollManager';
// import CookieAlert from './_main/CookieAlert';
// import Validation from './_main/Validation';
// import HeightSet from './_main/HeightSet';
// import Pagetop from './_main/Pagetop';
import Textarea from './_main/Textarea';
import InputFileButton from './_main/InputFileButton';

import MaterialsSlides from './_home/MaterialsSlides';
import ModalVideo from './_movies/ModalVideo';

{
    smoothscroll.polyfill();

    const domAccount = document.querySelector('[data-js="account"]');
    if (domAccount) new Account(domAccount);

    const domHamburger = document.querySelector('[data-js="trigger"]');
    if (domHamburger) new Hamburger(domHamburger);

    const domAnchorScroll = [...document.querySelectorAll('a[href^="#"]')];
    if ( domAnchorScroll ) {
        domAnchorScroll.forEach(el => {
            new AnchorScroll(el);
        });
    }

    const domAccordion = document.querySelector('[data-js="accordion"]');
    if (domAccordion) new Accordion(domAccordion);

    const domTextarea = [...document.querySelectorAll('[data-js="textarea"]')];
    if ( domTextarea ) {
        domTextarea.forEach(el => {
            new Textarea(el);
        });
    }

    const domInputFileButton = [...document.querySelectorAll('[data-js="input-file-button"]')];
    if ( domInputFileButton ) {
        domInputFileButton.forEach(el => {
            new InputFileButton(el);
        });
    }
}

{
    const domMaterialsSlides = document.querySelector('[data-js="splide-materials"]');
    if (domMaterialsSlides) new MaterialsSlides(domMaterialsSlides);
}

{
    const domModalVideos = document.querySelectorAll('[data-js="modal-trigger"]');
    if (domModalVideos) {
        new ModalVideo(domModalVideos);
    }
}
