export default class {
  constructor(el) {
    this.dom = {el: el};
    this.dom.input = this.dom.el.querySelector('input[type="file"]');
    this.dom.button = this.dom.el.querySelector('button');
    this.dom.fileName = this.dom.el.querySelector('& > div');

    this.init();
  }

  init() {

    this.dom.button.addEventListener('click', e => {
      this.dom.input.click();
    });

    this.dom.input.addEventListener('change', e => {
      const files = e.target.files;

      if (files.length === 0) {
        this.dom.fileName.innerText = '選択されていません';
        return;
      }

      this.dom.fileName.innerText = files[0].name;
    });
  }
}
